<template>
    <div
        class="container"
        style="background-color: #fff;min-height: calc(100vh - 300px);"
    >
        <div class="row">
            <div class="col-md-3 col-lg-2">
                {{ $store.state.language.agent.welcome }}
                {{ $store.state.user.name }}

                <div class="d-md-block py-2 pt-md-4 pb-md-0">
                    <router-link
                        class="btn btn-outline-primary d-inline-block d-md-block text-start ms-1 ms-md-0 me-1 me-md-0 mt-md-1"
                        to="/agent/customer_register"
                    >
                        <i class="fa fa-plus"></i>
                        {{
                            $store.state.language.agent.customer_register
                        }}</router-link
                    >
                    <router-link
                        class="btn  btn-outline-primary d-inline-block d-md-block text-start ms-1 ms-md-0 me-1 me-md-0 mt-md-1"
                        to="/agent/list"
                    >
                        <i class="fa fa-list"></i>
                        {{
                            $store.state.language.agent.customer_register_list
                        }}</router-link
                    >
                    <router-link
                        class="btn btn-outline-secondary d-inline-block d-md-block text-start ms-1 ms-md-0 me-1 me-md-0 mt-md-1"
                        to="/agent/change_pw"
                    >
                        <i class="fas fa-key me-1"></i
                        >{{ $store.state.language.agent.change_pw }}
                    </router-link>
                    <button
                        class="btn btn-secondary  text-start ms-1 ms-md-0 me-1 me-md-0 mt-md-4"
                        @click="$store.dispatch('logout')"
                    >
                        <i class="fas fa-sign-out-alt"></i>
                        {{ $store.state.language.agent.logout_btn }}
                    </button>
                </div>
            </div>
            <div class="col-md-9 col-lg-10">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    methods: {}
};
</script>
